<template>
  <section id="main-nanotech">
    <!-- #d59e0e30,#0a1a726b -->
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/main.jpg')"
      height="800"
      class="white--text"
      gradient="to bottom, rgba(11, 28, 61, .9), rgba(0,0,0,.4)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-subheading
            size="text-h5"
            weight="regular"
            title="SPECIALTY CHEMICAL SOLUTIONS COMPANY"
          />

          <base-heading
            size="text-h2"
            title="NANOTECH SOLUTIONS"
            weight="medium"
          />

          <base-body>
            <b class="text-h">Nanotech Solutions</b> is based in Malaysia specialising in specialty chemicals for a diverse number of industries including, rubber, glove, coating, paper, food, personal care and many others.
          </base-body>

          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <base-btn
              @click=fnGoToAbout
            >
              Discover More
            </base-btn>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionMainNanotech',
    data () {
      return {
        videoPath: require('@/assets/main_video.mp4'),
        videoPath480p: require('@/assets/main_video_mobile.mp4'),
        posterPath: require('@/assets/main_poster.jpg'),
      }
    },
    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
    methods: {
      fnGoToAbout () {
        this.$router.push('/about')
      },
    },
  }
</script>
